import React from "react";

import "./head.css"
function Head() {
    return (
        <div>
            <link
                rel="stylesheet"
                type="text/css"
                href="/css/bootstrap.min.css"
            />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:100,300,400|Raleway:100,300,400&display=swap" rel="stylesheet"/>
        </div>
    );
}

export default Head;
