import PropTypes from "prop-types"
import React from "react"

import "./header.css"
const Header = ({ siteTitle }) => (
  <div>
  <nav className="navbar navbar-expand-md fixed-top navbar-light bg-light">
    <div className="container">
      <a className="navbar-brand" href="/#">
        <img src="/images/logo-no-text square.png" style={{"marginBottom": 0,"width": "20px", "height": "20px","marginTop": "-8px"}} alt=""/>
        {siteTitle}
      </a>
      
    </div>
  </nav>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
